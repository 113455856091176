/* eslint-disable dot-notation */
import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm, autofill, formValueSelector, change } from 'redux-form';
import { withTranslation } from 'react-i18next';
import { createValidator, passengers } from 'utils/formValidations';
import { passengerCount } from 'utils/Reserbus';
import { setError, setAlert } from '@/actions';
import {
  savePassengersWithTickets,
  formErrors,
  deletePassenger,
  toggleWantsInsurance,
  insurancesChecked,
  clearTicketsErrorType,
} from '@/actions/purchase';
import Passengers from 'components/purchase/passengers/Passengers';
import purchaserValidations from 'utils/purchaserValidations';
import { setStoredPassenger } from 'utils/storedPassengers';
import { getStoredPurchaser, setStoredPurchaser } from 'utils/storedPurchaser';
import { getCountryCallingCode } from 'react-phone-number-input';
import { getDoc2BrandMap } from '../../../utils/documentMaps';
import { getUserByWalletType, isAccountData, needsLoyaltySelection } from '../../../utils/loyalty';
import { getInitialPassengers } from '../../../utils/passengers';

const validate = createValidator({
  ...purchaserValidations,
  passengers: [passengers],
});

/**
 * Check if the date of birth is valid
 * Go through all the passengers, verify if some of them have
 * the bus category "older" and, if so, check if those
 * passengers are 60 years old or older.
 *
 * Return false if at least one passenger
 * with the "older" bus category is not old enough.
 * Otherwise, return true.
 *
 * Return true if no passengers selected the "older" bus category.
 *
 * @param {Object} state - The current state.
 * @returns {boolean} - Whether all dates of birth are valid or not.
 */
const datesOfBirthAreValid = (state) => () => {
  const passengers = formValueSelector('passengers')(state, 'passengers');
  const olderPassengers = passengers.filter((passenger) => passenger.busCategory.includes('older'));

  const now = new Date();

  const sixtyOrOlder = olderPassengers.filter((passenger) => {
    const passengerBirthDate = passenger?.dateOfBirth || '';
    if (passengerBirthDate.length === 0) return false;

    const birthDate = new Date(passengerBirthDate);
    let age = now.getFullYear() - birthDate.getFullYear();
    const monthDiff = now.getMonth() - birthDate.getMonth();

    if (monthDiff < 0 || (monthDiff === 0 && now.getDate() < birthDate.getDate())) {
      age -= 1;
    }

    return age >= 60;
  });

  return sixtyOrOlder.length === olderPassengers.length;
};

/**
 * Validate the pet-friendly quantity.
 * Check if the number of pets taken by passengers with the "pet_friendly" bus category
 * is less than or equal to the number of adults.
 *
 * @param {Object} state - The current state.
 * @returns {boolean} - True if the pet quantity is valid or if no passengers are pet-friendly; false otherwise.
 */
const petFriendlyQuantityIsValid = (state) => () => {
  const passengers = formValueSelector('passengers')(state, 'passengers');
  const adultsCount = passengers.reduce(
    (acc, curr) =>
      curr.busCategory !== 'minor' && curr.busCategory !== 'pet_friendly' ? acc + 1 : acc,
    0,
  );
  const petsTaken = passengers.filter((curr) => curr.busCategory === 'pet_friendly').length;
  return adultsCount >= petsTaken;
};

const mapStateToProps = (state) => {
  const purchase = state.purchase.toJS();
  const {
    form,
    exchange,
    whitelabelConfig: { features, env },
    errors,
  } = state;
  const {
    departs,
    returns,
    passengerSelection,
    passengers,
    busCategories,
    documentId,
    documentType,
    total,
    purchaserFirstName,
    purchaserLastName,
    purchaserSecondLastName,
    phone,
    email,
    phoneCode,
    phoneCountry,
    ticketsErrorType,
    isExchange = false,
    allowsSeatSelection,
    walletType,
    addressAttributes,
  } = purchase;

  const user = getUserByWalletType(walletType);

  const getPassengerFormValues = formValueSelector('passengers');
  const storedPurchaser = getStoredPurchaser() || {};
  const phoneCountryValue = phoneCountry || env.lang?.default?.substring(3) || 'MX';
  const doc2BrandMapPurchaser = getDoc2BrandMap('purchaser');
  const purchaserDocumentsOptions = Object.values(doc2BrandMapPurchaser);

  // Checking if the first passenger is the same as the account logged in
  const isAccountThePassenger =
    // eslint-disable-next-line prettier/prettier
    user && (!passengers.length || !passengers[0]?.firstName || isAccountData(passengers[0], user));

  const passengersInitialValues = getInitialPassengers({
    departureSelectedSeats: departs.selectedSeats,
    returnSelectedSeats: returns?.selectedSeats,
    allowsSeatSelection,
    passengerSelection,
    departureTrips: departs.trips,
    returnTrips: returns?.trips || [],
  });

  return {
    purchase,
    isExchange,
    exchange,
    user,
    passengersForm: form,
    busCategories,
    totalPrice: parseFloat(total, 10),
    totalPassengers: passengerCount(passengerSelection),
    ticketsErrorType,
    purchaser: getPassengerFormValues(
      state,
      'purchaserFirstName',
      'purchaserLastName',
      'purchaserSecondLastName',
      'phone',
      'documentType',
      'documentId',
      'email',
    ),
    initialValues: {
      documentId: documentId || (!isExchange ? storedPurchaser.documentId : undefined),
      documentType:
        documentType ||
        (!isExchange ? storedPurchaser.documentType : undefined) ||
        purchaserDocumentsOptions[0] ||
        'CC',
      purchaserFirstName:
        purchaserFirstName || (!isExchange ? storedPurchaser.purchaserFirstName : undefined),
      purchaserLastName:
        purchaserLastName || (!isExchange ? storedPurchaser.purchaserLastName : undefined),
      purchaserSecondLastName:
        purchaserSecondLastName ||
        (!isExchange ? storedPurchaser.purchaserSecondLastName : undefined),
      email: email || (!isExchange ? storedPurchaser.email : undefined),
      phone: phone || (!isExchange ? storedPurchaser.phone : undefined),
      phoneCountry: storedPurchaser.phoneCountry || phoneCountryValue,
      phoneCode: phoneCode || getCountryCallingCode(phoneCountryValue),
      passengers: passengersInitialValues,
      addressAttributes: addressAttributes || storedPurchaser.addressAttributes || {},
    },
    insuranceMessage: state.purchase.getIn(['config', 'insuranceMessage']),
    insuranceAmount: state.purchase.getIn(['config', 'insuranceAmount']),
    insuranceAmountIsUnitPrice: state.purchase.getIn(['config', 'unitAmount']),
    features,
    env,
    datesOfBirthAreValid: datesOfBirthAreValid(state),
    petFriendlyQuantityIsValid: petFriendlyQuantityIsValid(state),
    // este user es el main user, verificar que funciona con costapass
    isLogged: user && user.email && passengersInitialValues[0].email === user.email,
    isAccountThePassenger,
    needsLoyaltySelector: needsLoyaltySelection(),
    errors,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  savePassengers: (fields, trips) =>
    dispatch(savePassengersWithTickets(ownProps.match.params.token, fields, trips)),

  onToggleWantsInsurance: (token, wants) => dispatch(toggleWantsInsurance(token, wants)),

  onSubmitFail: (fields) => {
    const isAdultRequired = fields.passengers.some(
      (passenger) => passenger.dateOfBirth === 'An adult must be present',
    );
    const msg = isAdultRequired ? 'adult_required' : 'check_the_fields_in_red';
    dispatch(setError(null, msg, 'warning', false, null, null, null, 'fixed'));
    dispatch(formErrors(1, fields));
  },

  autofillFirstPassenger: (
    firstName,
    lastName,
    secondLastName,
    documentType,
    documentId,
    email,
  ) => {
    dispatch(autofill('passengers', 'passengers[0].firstName', firstName));
    dispatch(autofill('passengers', 'passengers[0].lastName', lastName));
    dispatch(autofill('passengers', 'passengers[0].secondLastName', secondLastName));
    // dispatch(autofill('passengers', 'passengers[0].documentType', documentType));
    // dispatch(autofill('passengers', 'passengers[0].documentId', documentId));
    dispatch(autofill('passengers', 'passengers[0].email', email));
  },

  autofillInsurances: (passengerIndex, selectDepartureInsurances, selectReturnsInsurances) => {
    dispatch(
      autofill(
        'passengers',
        `passengers[${passengerIndex}].wantsOutgoingInsurance`,
        selectDepartureInsurances,
      ),
    );
    dispatch(
      autofill(
        'passengers',
        `passengers[${passengerIndex}].wantsIncomingInsurance`,
        selectReturnsInsurances,
      ),
    );
  },

  setInsurancesChecked: () => {
    dispatch(insurancesChecked());
  },

  deletePassenger: (passengerId) => {
    dispatch(deletePassenger(ownProps.match.params.token, passengerId));
  },

  transitionTo: (route) => ownProps.history.push(route),

  returnTo: (route) => ownProps.history.replace(route),

  showDateOfBirthWarning: () => dispatch(setAlert('date_of_birth', 'warning')),

  showPetFriendlySeatsWarn: () => dispatch(setAlert('adult_per_pet', 'warning')),

  showAcceptTermsWarning: () =>
    dispatch(
      setError(undefined, 'should_accept_terms', 'warning', false, null, null, null, 'fixed'),
    ),

  clearTicketsErrors: () => {
    dispatch(clearTicketsErrorType());
  },
});

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({
    form: 'passengers',
    /**
     * This redux config setting is optional for control when to validate
     * Since form is rendered conditional we force to validate always something change
     * @returns Boolean is validation should run
     */
    shouldValidate: () => true,
    onChange: (values, dispatch) => {
      const { documentId, documentType, passengers } = values;
      if ((documentType === 'CPF' || documentType === 'CNPJ') && documentId) {
        const valueWithOnlyDigits = documentId.replace(/\D/g, '');
        dispatch(change('passengers', 'documentId', valueWithOnlyDigits));
      }
      passengers.forEach(
        ({ documentId, documentType, documentIdSecond, documentTypeSecond }, index) => {
          if (documentType === 'RG' && documentId) {
            const rgDocumentWithOnlyDigitsAndLetters = documentId.replace(/[^a-zA-Z0-9]/g, '');
            dispatch(
              change(
                'passengers',
                `passengers[${index}].documentId`,
                rgDocumentWithOnlyDigitsAndLetters,
              ),
            );
          }
          if (documentTypeSecond === 'RG' && documentIdSecond) {
            const rgDocumentWithOnlyDigitsAndLetters = documentIdSecond.replace(
              /[^a-zA-Z0-9]/g,
              '',
            );
            dispatch(
              change(
                'passengers',
                `passengers[${index}].documentIdSecond`,
                rgDocumentWithOnlyDigitsAndLetters,
              ),
            );
          }
        },
      );
    },
    onSubmitSuccess: (result, dispatch, props) => {
      // TODO: merge these stored forms
      setStoredPassenger(props.values.passengers);
      setStoredPurchaser({
        ...props.values,
        passengers: undefined,
      });
    },
    validate,
  }),
  withTranslation('passengers'),
)(Passengers);
