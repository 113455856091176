import React from 'react';
import { useSelector } from 'react-redux';
import IAMSAForm from './IAMSAForm';
import GfaForm from './GfaForm';
import SNForm from './SNForm';

const components = {
  gfa: GfaForm,
  satelitenorte: SNForm,
  empresaunida: SNForm,
  expressosaoluiz: SNForm,
  rochoa: SNForm,
  default: IAMSAForm,
};

const FormLayout = (props) => {
  const { env } = useSelector((state) => state.whitelabelConfig);
  const Component = components[env.brand] ?? components.default;

  // eslint-disable-next-line react/jsx-props-no-spreading
  return <Component {...props} />;
};

export default FormLayout;
